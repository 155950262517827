import React, { useState, useEffect } from 'react';
import { ApiUrl, CommonStatus, OverallTransactionTypeId } from '../../../util/Constant';
import { getValidationMessage, numberWithCurrencyFormat } from '../../../util/Util';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DateTime from 'react-datetime';
import ReactTable from '../../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation } from "react-router-dom";
import { showResponseMessage } from '../../../redux/AppAction';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/// <summary>
/// Author: Wind
/// </summary>
const UserTransactionLog = props => {
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue } = useForm();
    let { search } = useLocation();
    const _query = new URLSearchParams(search);
    const _USERNAME = _query.get('username');
    const [logUrl, setLogUrl] = useState('');
    const [status, setStatus] = useState(CommonStatus._ALL_STATUS);
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));

    let _operationLogTableColumns = [
        {
            Header: t("DATE"),
            accessor: "createdTime",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {moment(row.original.createdTime).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                )
            },
        },
        {
            Header: t("OPERATED_BY"),
            accessor: "operatedByUsername",
            disableSortBy: true,
            Cell: ({ row }) => {
                let operatedBy = "";
                let rowDetail = row.original;

                switch (rowDetail.typeId) {
                    case OverallTransactionTypeId._SEND_CREDIT:
                        // Current user is a receiving, else sent credit to others
                        if (rowDetail.username == rowDetail.receiverUsername) {
                            operatedBy = rowDetail.senderUsername;
                        }
                        else {
                            operatedBy = rowDetail.receiverUsername;
                        }
                        break;
                    default:
                        operatedBy = rowDetail.operatedByUsername;
                        break;
                }

                return operatedBy
            }
        },
        {
            Header: t("MEMBER_USERNAME"),
            accessor: "username",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.username} ({row.original.regionName})
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: t("PLAYER_ID"),
            accessor: "playerUsername"
        },
        {
            Header: t("TYPE"),
            accessor: "type",
            disableSortBy: true,
            Cell: ({ row }) => {
                let transactionTypeMsg = "";
                let rowDetail = row.original;

                switch (rowDetail.typeId) {
                    case OverallTransactionTypeId._ONLINE_BANKING:
                        transactionTypeMsg = t("TOP_UP_GPAY_BY");
                        break;
                    case OverallTransactionTypeId._SHOP_SET_SCORE:
                        transactionTypeMsg = t("SHOP_SET_E_POINT_BY");
                        break;
                    case OverallTransactionTypeId._SHOP_SET_CREDIT:
                        transactionTypeMsg = t("SHOP_SET_E_CREDIT_BY");
                        break;
                    case OverallTransactionTypeId._SEND_CREDIT:
                        // Current user is a receiving, else sent credit to others
                        if (rowDetail.username == rowDetail.receiverUsername) {
                            transactionTypeMsg = t("RECEIVED_E_CREDIT_FROM", { operatedBy: rowDetail.senderUsername });
                        }
                        else {
                            transactionTypeMsg = t("SENT_E_CREDIT_TO", { operatedBy: rowDetail.receiverUsername });
                        }
                        break;
                    case OverallTransactionTypeId._CONVERT_TO_CREDIT:
                        transactionTypeMsg = t("CONVERT_TO_CREDIT_BY");
                        break;
                    case OverallTransactionTypeId._CONVERT_TO_SCORE:
                        transactionTypeMsg = t("CONVERT_TO_SCORE_BY");
                        break;
                    case OverallTransactionTypeId._TRANSFER_SCORE:
                        transactionTypeMsg = t("TRANSFER_E_POINT");
                        break;
                    case OverallTransactionTypeId._REWARD_TO_CREDIT:
                        // if claim reward is operated by member, then is convert to credit
                        if (rowDetail.username == rowDetail.operatedByUsername) {
                            transactionTypeMsg = t("CONVERT_REWARD_TO_CREDIT");
                        }
                        else {
                            transactionTypeMsg = t("CLAIM_REWARD");
                        }
                        break;
                    case OverallTransactionTypeId._EGHL:
                        transactionTypeMsg = t("PURCHASED_EGHL_BY");
                        break;
                    case OverallTransactionTypeId._E_WALLET_MXPAY:
                        transactionTypeMsg = t("TOP_UP_E_WALLET");
                        break;
                    case OverallTransactionTypeId._DUITNOW:
                        transactionTypeMsg = t("DUITNOW");
                        break;
                    case OverallTransactionTypeId._PASSWORD_RESET:
                        transactionTypeMsg = t("PASSWORD_RESET");
                        break;
                    case OverallTransactionTypeId._ONLINE_WITHDRAWAL:
                        transactionTypeMsg = t("ONLINE_WITHDRAWAL");
                        break;
                }

                return (
                    <span className='badge badge-primary' >
                        {transactionTypeMsg}
                    </span >
                )
            },
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status == CommonStatus._APPROVE_STATUS ? <span className="badge badge-green">{t("SUCCESS")}</span> : <span className="badge badge-danger">{t("FAILED")}</span>;
            },
            disableSortBy: true
        },
        {
            Header: t("MEMBER_PRE"),
            accessor: "preCredit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {numberWithCurrencyFormat(row.original.preCredit)}
                    </span>
                )
            }
        },
        {
            Header: t("MEMBER_POST"),
            accessor: "postCredit",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {numberWithCurrencyFormat(row.original.postCredit)}
                    </span>
                )
            }
        },
        {
            Header: t("AMOUNT"),
            accessor: "amount",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span className={row.original.amount > 0 ? 'text-success' : 'text-danger'}>
                        {numberWithCurrencyFormat(row.original.amount)}
                    </span>
                )
            }
        },
        {
            Header: t("PLAYER_PRE"),
            accessor: "preScore",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {numberWithCurrencyFormat(row.original.preScore)}
                    </span>
                )
            },
        },
        {
            Header: t("PLAYER_POST"),
            accessor: "postScore",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {numberWithCurrencyFormat(row.original.postScore)}
                    </span>
                )
            },
        },
        {
            Header: t("IP_ADDRESS"),
            accessor: "ipAddress",
            disableSortBy: true,
        },
        {
            id: "action",
            Header: t("ACTION"),
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                _dispatch(showResponseMessage(true, row.original.remark));
                            }}><span className="text-warning">{t("VIEW_REMARK")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ];

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        onSubmit();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    async function onSubmit() {
        var rawUrl = ApiUrl._API_GET_USER_TRANSACTION_LOG + "?Username=" + _USERNAME +
            "&StartDate=" + startDate + "&EndDate=" + endDate + "&Status=" + status +
            "&v=" + moment.now();
        setLogUrl(rawUrl);
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onSubmit() {
        var rawUrl = ApiUrl._API_GET_USER_TRANSACTION_LOG + "?Username=" + _USERNAME +
            "&StartDate=" + startDate + "&EndDate=" + endDate + "&Status=" + status +
            "&v=" + moment.now();
        setLogUrl(rawUrl);
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                    <h1 className="page-header">{t("USER_TRANSACTION_LOG") + ' (' + _USERNAME + ')'}</h1>
                </div>
                <Panel>
                    <PanelBody>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select
                                        name="status"
                                        className="form-control form-control-lg"
                                        value={status}
                                        onChange={e => {
                                            setStatus(e.target.value);
                                        }}
                                    >
                                        <option value={CommonStatus._ALL_STATUS}>{t("ALL")}</option>
                                        <option value={CommonStatus._APPROVE_STATUS}>{t("SUCCESS")}</option>
                                        <option value={CommonStatus._REJECT_STATUS}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('BTN_SUBMIT')}</button>
                            </div>
                        </div>
                    </PanelBody>
                </Panel>
                <Panel>
                    <div className="row">
                        <div className="col-lg-12">
                            <PanelBody>
                                <ReactTable fetchUrl={logUrl} columns={_operationLogTableColumns} initialPageSize={500} />
                            </PanelBody>
                        </div>
                    </div>
                </Panel>
            </form>
        </div>
    );
}

export default UserTransactionLog;